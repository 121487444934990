.roma-disclaimer {
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 8px;
  width: 100%;

  font-family: Metropolis;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;

  &.warning {
    background: #fff1db;
  }

  &.error {
    background: #feebed;
    color: #6f1a21;
  }

  &.info {
    background: transparent;
    color: #6a79a0;
    .icon {
      display: flex;
      align-items: flex-start;
      height: 100%;
    }
  }

  &.dark {
    background: #202046;
    color: white;
  }

  &.small {
    .icon {
      img {
        height: 18px;
        width: 18px;
      }
    }
  }

  .icon {
    img {
      width: 32px;
      height: 32px;
    }
    &.size-24 {
      img {
        width: 24px;
        height: 24px;
      }
    }

    &.circle {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 36px;
      width: 36px;
      border-radius: 50%;
      background-color: #2c2c61;
      img {
        height: 24px;
        width: 24px;
      }
    }

    &.top {
      align-self: flex-start;
    }
    &.centered {
      align-self: center;
    }
  }

  .body {
    flex: 1;
    padding-left: 12px;

    .roma-button {
      margin-top: 8px;
      font-size: 11px;
      height: 36px;
      width: fit-content;
    }

    &.block {
      display: flex;
      flex-direction: column;
    }

    @media screen and (min-width: 992px) {
      display: flex;
      align-items: center;
      .roma-button {
        margin-left: 10px;
        margin-top: 0;
      }
    }
  }

  .options {
    padding: 0 9px;
  }

  .highlight {
    font-weight: 700;
  }
}
