@font-face {
  font-family: 'Metropolis';
  src: url('https://uploads-ssl.webflow.com/609c04240a7687df535127d7/609c055e08bd0e72082125ed_Metropolis-Black.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Metropolis';
  src: url('https://uploads-ssl.webflow.com/609c04240a7687df535127d7/609c055e12817f0503f7130d_Metropolis-BlackItalic.otf') format('opentype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Metropolis';
  src: url('https://uploads-ssl.webflow.com/609c04240a7687df535127d7/609c055e8739bdc1d87bcf4c_Metropolis-BoldItalic.otf') format('opentype'), url('https://uploads-ssl.webflow.com/609c04240a7687df535127d7/609c055e8739bd7eff7bcf4d_Metropolis-ExtraBoldItalic.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Metropolis';
  src: url('https://uploads-ssl.webflow.com/609c04240a7687df535127d7/609c055e031524f20a530fe6_Metropolis-Bold.otf') format('opentype'), url('https://uploads-ssl.webflow.com/609c04240a7687df535127d7/609c055ee14e1172fe391462_Metropolis-ExtraBold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Metropolis';
  src: url('https://uploads-ssl.webflow.com/609c04240a7687df535127d7/609c055e08bd0e29242125ee_Metropolis-ExtraLight.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Metropolis';
  src: url('https://uploads-ssl.webflow.com/609c04240a7687df535127d7/609c055e12817f5653f7130e_Metropolis-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Metropolis';
  src: url('https://uploads-ssl.webflow.com/609c04240a7687df535127d7/609c055e4a4702eaeeb34bd2_Metropolis-LightItalic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Metropolis';
  src: url('https://uploads-ssl.webflow.com/609c04240a7687df535127d7/609c055ed4d8651d13eda0a0_Metropolis-ExtraLightItalic.otf') format('opentype');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Metropolis';
  src: url('https://uploads-ssl.webflow.com/609c04240a7687df535127d7/609c055ed4d86500cbeda0a1_Metropolis-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Metropolis';
  src: url('https://uploads-ssl.webflow.com/609c04240a7687df535127d7/609c055faf7d344fdcd622b5_Metropolis-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Metropolis';
  src: url('https://uploads-ssl.webflow.com/609c04240a7687df535127d7/609c055f13d953190ad00dff_Metropolis-SemiBold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Metropolis';
  src: url('https://uploads-ssl.webflow.com/609c04240a7687df535127d7/609c055f1114075e02b26169_Metropolis-Thin.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Metropolis';
  src: url('https://uploads-ssl.webflow.com/609c04240a7687df535127d7/609c055fceca4912a1da7598_Metropolis-MediumItalic.otf') format('opentype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Metropolis';
  src: url('https://uploads-ssl.webflow.com/609c04240a7687df535127d7/609c055f252ce914803d1bae_Metropolis-RegularItalic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Metropolis';
  src: url('https://uploads-ssl.webflow.com/609c04240a7687df535127d7/609c055f4cf184237f3cf1d8_Metropolis-SemiBoldItalic.otf') format('opentype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Metropolis';
  src: url('https://uploads-ssl.webflow.com/609c04240a7687df535127d7/609c055f7ec8f333c786b78d_Metropolis-ThinItalic.otf') format('opentype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}