.page-title {
  font-size: 28px !important;
  font-weight: 600 !important;
  letter-spacing: -0.1px !important;
  .tooltip-container {
    position: relative;
    display: inline-block;
    width: 34px;
    .help-icon {
      margin-left: 10px;
      height: 32px;
      cursor: pointer;
      @media screen and(max-width: 768px) {
        margin-left: 0;
      }
    }
    .title-tooltip-wrapper {
      position: fixed;
      width: 100vw;
      height: 100vh;
      z-index: 1;
      left: 0;
      top: 0;
    }
    .help-tooltip {
      position: absolute;
      z-index: 2;
      width: 454px;
      transform: translateY(-10px);
      top: -20%;
      left: 150%;
      background: #ffffff;
      border-radius: 6px;
      padding: 16px;
      filter: drop-shadow(0px 4px 4px rgba(195, 204, 229, 0.25))
        drop-shadow(0px 2px 16px rgba(106, 121, 160, 0.48));
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #202046;
      @media screen and(max-width: 768px) {
        display: none !important;
      }
      &::after {
        content: ' ';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px 10.5px 0 10.5px;
        border-color: #fff transparent transparent transparent;
        position: absolute;
        top: 20%;
        left: -3%;
        transform: rotate(90deg);
      }
      a {
        color: #3c48ec;
        font-weight: normal !important;
        text-decoration: underline !important;
      }
    }
  }
}

.page-subtitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #6a79a0;
  margin-bottom: 0 !important;
}

.card-title {
  font-size: 1rem !important;
  font-weight: 600;
}
