// override material elements
@media screen and (max-width: 768px) {
  .mat-select-panel {
    .mat-option {
      font-size: 14px !important;
      padding: 0 10px !important;
      height: 2.5em !important;
    }
  }
}
