.header-primary {
  display: flex;
  align-items: center;
  padding: 15px 0;
  width: 100%;

  font: 400 24px/32px Roboto, 'Helvetica Neue', sans-serif;

  .header-controls {
    display: flex;
    align-items: center;
  }

  .header-option {
    display: flex;
    justify-content: center;
    align-items: center;

    mat-icon {
      cursor: pointer;
      color: #ccc;
      font-size: 18px;
    }
  }

  .header-title {
    font-size: 20px;
    font-weight: 500;
    padding: 0 15px;
    margin: 0;
    cursor: pointer;
  }

  .chip-view {
    cursor: pointer;

    &.active {
      background-color: gray;
      color: white;
    }
  }

  .header-legend {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .legend-item {
      display: flex;
      align-items: center;

      font-size: 14px;
      font-weight: 400;

      .legend-point {
        height: 16px;
        width: 16px;
        background-color: gray;
        border-radius: 50%;
      }

      .legend-text {
        padding-left: 8px;
        padding-right: 14px;
      }

      &:last-child {
        .legend-text {
          padding-right: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 996px) {
  .header-primary {
    flex-direction: column;

    .header-title {
      padding-bottom: 10px;
    }
  }
}

@media screen and (max-width: 768px) {
  .header-primary {
    .header-legend {
      flex-wrap: wrap;
      justify-content: center;
    }
  }
}
