@import './normalize';

button:focus {
  outline: none;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Manrope, Roboto, 'Helvetica Neue', sans-serif;
}
