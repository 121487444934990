// Colors
$taclia: #3c48ec;
$dark-primary-text: rgba(black, 0.87);
$light-primary-text: white;
$background: rgba(255, 255, 255, 0);

$taclia_yellow: palegoldenrod;
$taclia_orange: coral;
$taclia_brown: burlywood;
$taclia_blue: cornflowerblue;
$taclia_red: indianred;
$taclia_green: #8bc34a;
$taclia_purple: palevioletred;

$mobile-safe-area: 
  env(safe-area-inset-top) 
  env(safe-area-inset-right) 
  env(safe-area-inset-bottom) 
  env(safe-area-inset-left) !important;

$tl-50: lighten($taclia, 25%);
$tl-40: lighten($taclia, 20%);
$tl-30: lighten($taclia, 15%);
$tl-20: lighten($taclia, 10%);
$tl-10: lighten($taclia, 5%);
$td-10: darken($taclia, 5%);
$td-20: darken($taclia, 10%);
$td-30: darken($taclia, 20%);
$td-40: darken($taclia, 30%);
$td-50: darken($taclia, 40%);

$secondary: #3c48ec;
$sl-50: lighten($secondary, 25%);
$sl-40: lighten($secondary, 20%);
$sl-30: lighten($secondary, 15%);
$sl-20: lighten($secondary, 10%);
$sl-10: lighten($secondary, 5%);
$sd-10: darken($secondary, 5%);
$sd-20: darken($secondary, 10%);
$sd-30: darken($secondary, 20%);
$sd-40: darken($secondary, 30%);
$sd-50: darken($secondary, 40%);

$mat-taclia: (
  50: $tl-50,
  100: $tl-40,
  200: $tl-30,
  300: $tl-20,
  400: $tl-10,
  500: $taclia,
  600: $td-10,
  700: $td-20,
  800: $td-30,
  900: $td-40,
  A100: $tl-10,
  A200: $tl-20,
  A400: $taclia,
  A700: $td-20,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);

$mat-secondary: (
  50: $sl-50,
  100: $sl-40,
  200: $sl-30,
  300: $sl-20,
  400: $sl-10,
  500: $secondary,
  600: $sd-10,
  700: $sd-20,
  800: $sd-30,
  900: $sd-40,
  A100: $sl-10,
  A200: $sl-20,
  A400: $secondary,
  A700: $sd-20,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);

:root {
  // containers colors
  --container-option: #eef1f3;

  // form elements
  --filter-range-width: 350px;
  --filter-input-width: 350px;
  --filter-select-width: 350px;

  --taclia: #3c48ec;
}
