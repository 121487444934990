.roma-label {
  display: flex;
  align-items: center;
  padding: 2px 4px;
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 18px;
  border-radius: 6px;
  &.warning {
    background-color: #fff1db;
    color: #202046;
  }
  .icon {
    padding-right: 6px;
    img {
      width: 14px;
      height: 14px;
    }
  }
  &.dark {
    background-color: #202046;
    color: white;
  }
  &.right-center {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    z-index: 10;
  }
  &.static-right-center {
    margin-left: 10px;
  }
}

.roma-status-wrapper {
  font-size: 11px;
  padding: 2px 8px;
  border-radius: 6px;
  text-transform: uppercase;
  letter-spacing: 0.6px;
  font-weight: 600;
  height: 20px;
  display: flex;
  align-items: center;
  width: min-content;

  &.warn {
    background: #ffefd6;
    color: #ffab0f;
  }

  &.red {
    background: #ffebeb;
    color: #ff474e;
  }

  &.blue {
    background: #e1e2fc;
    color: #4d58eb;
  }

  &.green {
    background: #dff6e8;
    color: #36bf6a;
  }
}
