:root {
  --custom-modal_radius: 20px;
  --custom-modal_padding: 40px;
  --custom-modal_shadow: 0px 0px 30px rgba(0, 0, 0, 0.4);
  --custom-modal_footer_separe_content: 30px;
  --custom-modal_text_color: var(--color_green_900);
  --custom-modal_separe_content: 30px;
  --custom-text-modal_title: 24px;
}

.cdk-overlay-container {
  z-index: 10000;
}

.cdk-overlay-dark-backdrop {
  background: rgb(29 29 41 / 70%) !important;
}

.no-border-container .mat-dialog-container {
  border-radius: 0px !important;
}

.mat-dialog-container {
  border-radius: var(--custom-modal_radius) !important;
  max-height: 100vh !important;
  min-height: auto !important;
  padding: 0px !important;
  overflow: hidden !important;
  &::-webkit-scrollbar {
    width: 6px;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d3dbf2;
    border-radius: 100px;
  }
  .form-group,
  .ng-select,
  .container-phones {
    margin-bottom: 16px !important;
  }

  .ng-select {
    .ng-clear-wrapper {
      top: 5px;
    }
    .ng-arrow-wrapper {
      .ng-arrow {
        top: 3px;
      }
    }
    &.ng-select-multiple {
      .ng-select-container {
        .ng-value-container {
          padding-top: 0;
          padding-left: 0;
          .ng-placeholder {
            top: 10px;
            padding-left: 0;
            transform: none;
          }
          .ng-value {
            margin: 0;
          }
        }
      }
    }
  }

  input.form-control,
  textarea,
  .phone-input,
  .ng-select .ng-select-container {
    font-family: Metropolis;
    margin: 0;
    background: #fff;
    border: 1px solid #d3dbf2 !important;
    box-sizing: border-box;
    border-radius: 9px !important;
    min-height: 36px;
    font-size: 14px !important;
    color: #414b66 !important;
    padding: 0.375rem 1rem;
    &:focus {
      outline: 0;
      box-shadow: none;
    }
    &:disabled {
      background-color: #f2f6fc !important;
      color: #97a3c5 !important;
    }
    &[type='date'] {
      background-image: url('../../assets/icons/gl_calendar-input.svg');
      background-repeat: no-repeat;
      background-position: calc(100% - 16px) center;
      &::-webkit-inner-spin-button,
      &::-webkit-calendar-picker-indicator {
        opacity: 0;
        -webkit-appearance: none;
        cursor: pointer;
      }
    }
  }

  input.form-control.is-invalid,
  ng-select.is-invalid .ng-select-container {
    border: 1px solid #ff7075 !important;
  }

  input::placeholder,
  .ng-placeholder {
    color: #97a3c5 !important;
  }
  .ng-select .ng-select-container .ng-value-container {
    padding-left: 0;
  }

  .mat-form-field {
    margin-bottom: 16px !important;
  }

  .mat-form-field-appearance-outline .mat-form-field-wrapper {
    margin: 0;
    padding: 0;
  }

  .mat-form-field-flex > .mat-form-field-infix {
    padding: 8.5px 0px 10.5px 0px !important;
  }
  .mat-form-field-label-wrapper {
    top: -17px;
  }

  .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float
    .mat-form-field-label {
    transform: translateY(-1.1em) scale(0.75);
    width: 133.33333%;
  }

  .mat-form-field-appearance-outline {
    .mat-form-field-outline-start,
    .mat-form-field-outline-end,
    .mat-form-field-outline-gap {
      border: 1px solid #d3dbf2;
    }
    .mat-form-field-outline-start {
      border-radius: 9px 0 0 9px;
      border-right-style: none;
      width: 9px !important;
    }
    .mat-form-field-outline-end {
      border-radius: 0 9px 9px 0;
      border-left-style: none;
    }
    .mat-form-field-outline-gap {
      border-left-style: none;
      border-right-style: none;
    }
  }
}

.custom-modal__user {
  border-radius: 0px !important;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.4) !important;
  .mat-menu-content {
    padding: 0px !important;
    border-radius: 0px;
  }
}

.custom-modal {
  display: flex;
  flex-direction: column;
  color: var(--custom-modal_text_color);
  padding: var(--custom-modal_padding) 0px;
  padding-bottom: 0px;
  font-family: var(--custom-text_font);
  position: relative;
  z-index: 1;
  overflow: hidden;
  max-height: 100dvh;
  &.lg {
    width: 100%;
  }
  &.md {
    width: 600px;
    @media screen and (max-width: 576px) {
      min-width: auto;
      width: 100%;
    }
  }
  &.sm {
    min-width: 416px;
    width: 416px;
    @media screen and (max-width: 576px) {
      min-width: auto;
      width: 100%;
    }
  }
  &.mobile-50h {
    height: 50%;
    top: none;
  }
  &.mobile-70h {
    height: 70%;
    top: none;
  }
}

.custom-modal__close {
  cursor: pointer;
  height: 24px;
  display: flex;
  justify-content: end;
  align-items: end;
  z-index: 3;
}

.custom-modal__header {
  position: relative;
  padding-right: calc(var(--custom-modal_padding));
  padding-left: var(--custom-modal_padding);
  word-break: break-all;
  overflow-wrap: break-word;
  font-size: var(--custom-text-modal_title);
  letter-spacing: -1px;
  @media screen and (max-width: 768px) {
    width: 100vw;
  }

  > *:only-child {
    margin-left: auto;
  }
}

.custom-modal__header__title {
  display: inline-block;
  font-weight: 600;
  font-size: 20px;
  letter-spacing: -0.1px;
}

.custom-modal__body {
  --scrollbarBG: #cfd8dc;
  --thumbBG: #90a4ae;

  display: flex;
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0px var(--custom-modal_padding);

  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    // border-radius: 10px;
    background: var(--scrollbarBG);
  }

  &::-webkit-scrollbar-thumb {
    // border-radius: 10px;
    // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    background-color: var(--thumbBG);
    border-radius: 6px;
    border: 3px solid var(--scrollbarBG);
  }
}

.custom-modal__body__message {
  word-break: break-word;
  overflow-wrap: break-word;
}

.custom-modal__footer {
  display: flex;
  justify-content: flex-end;
  padding: var(--custom-modal_padding);
  padding-top: var(--custom-modal_separe_content);
}

.disclaimer {
  background: #f2f6fc;
  border-radius: 8px;
  padding: 12px 36px;
  font-size: 12px;
  line-height: 16px;
  color: #414b66;
  position: relative;
  .icon {
    position: absolute;
    left: 14px;
  }
}

.radio-group {
  display: block;
  .mat-radio-button {
    border: 1px solid #d3dbf2;
    border-radius: 6px;
    padding: 10px 12px;
    height: 40px;
    margin-right: 10px;
    .mat-radio-outer-circle {
      border: 1px solid #d3dbf2;
    }
    .mat-radio-ripple {
      visibility: hidden;
    }
    &.mat-accent {
      .mat-radio-inner-circle {
        background-color: #ffffff;
      }
      &.mat-radio-checked {
        border-color: #36bf6a;
        .mat-radio-outer-circle {
          border-color: #36bf6a;
          border-width: 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  :root {
    --custom-modal_padding: 10px;
  }

  .custom-modal.custom-modal--mobile-full {
    min-width: 100vw;
    width: 100vw;
    position: fixed;
    min-height: 100%;
    left: 0;
    top: 0;
    background: white;
    height: 100%;
    padding: env(safe-area-inset-top) env(safe-area-inset-right)
      env(safe-area-inset-bottom) env(safe-area-inset-left) !important;
    overflow-y: hidden !important;

    .custom-modal__body {
      overflow-x: hidden;
      overflow-y: auto;
    }

    &.mobile-50h {
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      min-height: 50%;
      height: 50%;
      top: auto;
      bottom: 0px;
      padding-top: 0px !important;
    }

    &.mobile-70h {
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      min-height: 70%;
      height: 70%;
      top: auto;
      bottom: 0px;
      padding-top: 0px !important;
    }

    .custom-modal__footer {
      .custom-content--btns {
        [class*='custom-btn'] {
          margin-bottom: 10px;
          &:last-child {
            margin: 0px;
          }
        }
      }
    }
  }
}
