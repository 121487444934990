.btn-download {
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  border: 1px solid #d3dbf2;
  border-radius: 80px;
  letter-spacing: 0.1px;
  color: #202046;
  padding: 10px 12px;
  background: white !important;
  text-decoration: none;
  cursor: pointer;
  margin-right: 5px;
  &:hover {
    color: #3c48ec;
    .download-icon {
      filter: invert(33%) sepia(35%) saturate(3187%) hue-rotate(220deg)
        brightness(93%) contrast(98%);
    }
  }
  &.disable-btn {
    color: #d3dbf2;
    border-color: #d3dbf2;
    pointer-events: none;
  }
  .download-icon {
    margin-left: 5px;
    width: 16px;
  }

  &.user-feature {
    &:not(.is-user-available) {
      opacity: 0.7;
    }
  }
}

.roma-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 90px;
  padding: 10px 16px;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  min-width: 110px;
  letter-spacing: 0.1px;
  text-align: center;
  text-decoration: none;
  height: 36px;
  line-height: 18px;
  background-color: #3c48ec;
  color: #ffffff;
  border: transparent;
  outline: none;

  &.dark {
    justify-content: center;
    height: 44px;
    background: #202046;
    border-radius: 90px;
    color: white;
    font-family: 'Metropolis';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.1px;
    img {
      margin-right: 4px;
      width: 18px;
      height: 18px;
    }
  }

  &.danger {
    background-color: #ff474e;
  }
  &.outline {
    background-color: transparent;
    border: 1px solid #3c48ec;
    color: #3c48ec;
    &.outline-gray {
      border: 1px solid #e3ebfa;
    }
  }
  &.transparent {
    background-color: white;
    border: 1px solid #d3dbf2;
    color: #15152e;
  }
  &.dark-text {
    color: #15152e;
  }

  .icon-star-pro-feature {
    height: 20px;
    width: 20px;
    margin-right: 8px;
  }

  .icon-option {
    margin-left: 8px;
    margin-top: -2px;
  }

  &.full-width {
    width: 100%;
  }

  &.big {
    height: 52px;
  }

  &.small {
    width: auto;
  }

  &.mobile-small {
    @media screen and (max-width: 992px) {
      // max-width: 120px;
    }
  }

  &.add-button-3 {
    @media screen and(max-width: 992px) {
      width: auto;
      height: 36px;
      line-height: 16px;
    }
  }

  &.user-feature {
    &:not(.is-user-available) {
      background: #dce3fb !important;
      color: #8c9dd2 !important;
    }
  }
}

button.mat-menu-item {
  &.user-feature {
    &:not(.is-user-available) {
      opacity: 0.7 !important;
    }
  }
}

button.add-field-btn {
  &.user-feature {
    &:not(.is-user-available) {
      opacity: 0.7 !important;
    }
  }
}

button.item-button {
  &.user-feature {
    &:not(.is-user-available) {
      opacity: 0.7 !important;
    }
  }
}

.roma-button-outline-light {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 112px;
  padding: 10px 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  min-width: 110px;
  letter-spacing: 0.1px;
  text-align: center;
  text-decoration: none;
  height: 36px;
  line-height: 18px;
  border: transparent;
  outline: none;
  cursor: pointer;
  background-color: white;
  color: #3c48ec;

  .btn-icon {
    margin-right: 8px;
    filter: brightness(0) saturate(100%) invert(29%) sepia(91%) saturate(6378%)
      hue-rotate(238deg) brightness(97%) contrast(91%);
  }

  &.edit-info {
    margin-right: 20px;
  }

  &:hover {
    background-color: #f8f8f8;
  }
}

.add-more-columns-btn {
  height: 32px;
  position: absolute;
  width: 68px;
  display: flex;
  align-items: center;
  margin-left: 8px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600px;
  line-height: 16px;
  letter-spacing: 0.012px;
  cursor: pointer;
  img {
    margin: 6px;
  }
  &:hover {
    color: #3c48ec !important;
    img {
      content: url('../../assets/icons/gl_plus-btn-blue.svg');
    }
  }
}

.add-more-columns-btn-active {
  background-color: #e3ebfa !important;
  border-radius: 100px !important;
  color: #3c48ec !important;
  img {
    content: url('../../assets/icons/gl_plus-btn-blue.svg');
  }
}

.roma-button-outline-secondary {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 112px;
  padding: 10px 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  min-width: 110px;
  letter-spacing: 0.1px;
  text-align: center;
  text-decoration: none;
  height: 36px;
  line-height: 18px;
  border: 1px solid #d2dbfc;
  outline: none;
  cursor: pointer;
  background-color: white;
  color: #15152e;

  .btn-icon {
    filter: brightness(0) saturate(100%) invert(8%) sepia(50%) saturate(654%)
      hue-rotate(199deg) brightness(98%) contrast(100%);

    &.suffix {
      margin-left: 8px;
    }

    &.prefix {
      margin-right: 8px;
    }
  }

  &:hover,
  &:active,
  &.active {
    background-color: #f7f9ff;
  }
}
