.paginate-container {
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 25px 0;
  position: fixed;
  bottom: 14px;
  z-index: 99;
  right: 50%;
  left: 50%;
  @media screen and (max-width: 768px) {
    z-index: 100;
    right: 0;
    left: 0;
    text-align: center;
    bottom: 40px;
  }
}

.container-buttons {
  display: flex;
  flex-direction: column;
  width: 100%;
  .roma-disclaimer {
    margin-bottom: 28px;
  }
}

.container__filters {
  display: flex;
  align-items: center;
  roma-rm-filter-button,
  roma-rm-filter-clear {
    padding-left: 14px;
    @media screen and (max-width: 768px) {
      padding-left: 8px;
      &:first-child {
        padding-left: 0px;
      }
    }
  }
  &--right {
    justify-content: flex-end;
    .roma-button {
      margin-left: 15px;
    }
  }
}
