@import './variables';

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '@angular/material/theming';

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core;

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$appTaclia-primary: mat-palette($mat-taclia);
$appTaclia-accent: mat-palette($mat-secondary);

// The warn palette is optional (defaults to red).
$appTaclia-warn: mat-palette($mat-orange, 400);

// Create the theme object (a Sass map containing all of the palettes).
$appTaclia-theme: mat-light-theme(
  $appTaclia-primary,
  $appTaclia-accent,
  $appTaclia-warn
);
// $appTaclia-theme: mat-dark-theme($appTaclia-primary, $appTaclia-accent, $appTaclia-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($appTaclia-theme);

.mat-table {
  //max-width: 90%;
  .mat-row {
    padding-top: 8px;
  }
}
