/* You can add global styles to this file, and also import other style files */
$enable-important-utilities: false !default;
@import '~bootstrap/scss/bootstrap';
@import './styles/base/variables';

:root {
  color-scheme: only light;
  overflow: hidden;
}

html,
body {
  font-family: Metropolis !important;
  height: 100%;
  overflow-x: hidden;
  margin: 0;
  @media only screen and (max-width: 992px) {
    overflow-y: hidden;
  }
}
body {
  margin: 0;
  font-family: Metropolis !important;
  padding: $mobile-safe-area;
}

body * {
  -webkit-font-smoothing: subpixel-antialiased !important;
  text-rendering: optimizeLegibility !important;
}

.modalFooter {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
  white-space: nowrap;
  min-height: 52px;
  width: 100%;
  padding: 40px 40px;
  .leftDiv {
    display: flex;
    align-content: start;
    width: 50%;
  }

  .groupEndDiv {
    display: flex;
    gap: 10px;
    align-items: end;
    justify-content: flex-end;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  .groupDiv {
    display: flex;
    gap: 10px;
    width: 50%;
  }

  .fullDiv {
    width: 100%;
  }

  &.center {
    justify-content: center;
  }

  &.quickCollapse {
    @media screen and (max-width: 500px) {
      .leftDiv {
        width: 100%;
      }
      .groupDiv {
        width: 100%;
      }
      flex-direction: column;
    }
  }

  @media screen and (max-width: 390px) {
    flex-direction: column;
  }

  @media screen and (max-width: 768px) {
    padding: 16px 24px;
    button,
    base-button {
      width: 100%;
      max-width: 100%;

      &.leftButton {
        margin-right: auto;
      }
    }
  }
}

button,
.mat-radio-button,
.mat-select {
  font-family: Metropolis !important;
}
h1,
h2,
h3,
h4 {
  font-family: Metropolis !important;
}
mat-table {
  font-family: Metropolis !important;
}
mat-card {
  font-family: Metropolis !important;
}

.info {
  color: #3c48ec;
}

.mat-snack-bar-handset .mat-snack-bar-container {
  height: fit-content !important;
  max-height: 48px !important;
}

::ng-deep {
  .ng-dropdown-panel .ng-dropdown-panel-items {
    max-height: 100px !important;
  }

  .mat-datepicker-actions {
    display: block;
    position: absolute;
    bottom: 6px;
    right: 6px;
  }
}

.info2 {
  position: absolute;
  right: var(--custom-modal_padding);
  top: 50px;
  right: 40px;
  cursor: pointer;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  color: #3c48ec;
}

.tutorial {
  background: #ffffff;
  color: #3c48ec;
  border: 1px solid #3c48ec;
  display: inline-flex;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  padding: 8px 12px;
  margin-right: 12px;
  border-radius: 112px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
  .video {
    width: 16px;
    height: 16px;
    margin-left: 8px;
    cursor: pointer;
  }
  @media screen and(max-width: 768px) {
    margin-right: 0;
  }
  &.user-feature {
    &:not(.is-user-available) {
      background: #dce3fb;
      color: #8c9dd2;
    }
  }
}

.create-user-button {
  width: max-content;
  background: #ffffff;
  color: #3c48ec;
  border: 1px solid #3c48ec;
  display: inline-flex;
  align-items: center;
  text-align: center;
  padding: 8px 12px;
  border-radius: 112px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
  strong {
    width: max-content;
  }
  .plus-svg {
    width: 16px;
    height: 16px;
    margin-left: 8px;
    cursor: pointer;
  }
}

.text-right {
  text-align: right;
}

.message {
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 25px 0;
  position: fixed;
  z-index: 99;
  right: 50%;
  left: 50%;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.1rem;
  @media screen and (max-width: 768px) {
    text-align: center;
    position: relative;
    left: 0;
  }
}

.containerTable {
  flex: 1;
  display: grid;
  height: 100%;
  min-height: 200px;
  width: 100%;
  overflow-x: scroll;
  overflow-y: scroll;
  background-color: #ffffff;
  --scrollbarBG: #cfd8dc;
  --thumbBG: #90a4ae;
  z-index: 1;
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ced3df;
    border-radius: 90px;
  }
  .my-4 {
    margin-top: 0 !important;
    .table-container {
      padding: 0 !important;
    }
  }

  @media screen and (min-width: 768px) {
    z-index: 1;
  }
}

.pointer {
  cursor: pointer;
}

.taclia {
  background-color: #3c48ec !important;
}

@media (max-width: 950px) {
  .info2 {
    top: 36px;
  }
  .btn:focus {
    outline: 0;
    box-shadow: none !important;
  }
}

@media (max-height: 590px) {
  .cdk-overlay-pane {
    min-height: 100% !important;
  }
}

.col-download {
  text-align: right;
  padding: 0;
  button:focus {
    outline: 0 !important;
    box-shadow: none !important;
    background-color: #e3ebfa;
  }
}

@media screen and (max-width: 900px) {
  div#hubspot-messages-iframe-container {
    visibility: hidden;
  }
}

::ng-deep {
  .ng-dropdown-panel .ng-dropdown-panel-items {
    max-height: 100px !important;
  }
}

h2 {
  font-family: Metropolis;
  font-weight: 600;
  font-size: 24px;
  color: #15152e;
}

h3 {
  font-family: Metropolis;
  font-weight: 600;
  font-size: 24px;
  color: #15152e;
}

h4 {
  &.sub-title {
    font-weight: 400;
    font-size: 18px;
    margin: 32px 0 20px 0 !important;
  }
}

.custom-tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
  width: auto;
  .tiptext {
    visibility: hidden;
    width: 125px;
    background-color: #202046;
    color: #fff;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    border-radius: 3px;
    padding: 4px 8px;
    position: absolute;
    z-index: 1;
    font-weight: 400;
    &.left {
      text-align: left !important;
    }
    &::after {
      content: '';
      position: absolute;
      border-width: 5px;
      border-style: solid;
    }
  }
  &:hover {
    .tiptext {
      visibility: visible;
      @media screen and (max-width: 768px) {
        animation: css 0s 5s forwards;
        @keyframes css {
          to {
            visibility: hidden;
          }
        }
      }
    }
  }
  &.bottom {
    .tiptext {
      margin-left: -60px;
      top: 160%;
      left: 50%;
      &::after {
        margin-left: -5px;
        bottom: 100%;
        left: 50%;
        border-color: transparent transparent #2e2e2e transparent;
      }
    }
  }
  &.top {
    .tiptext {
      top: -100%;
      &::after {
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-color: #202046 transparent transparent transparent;
      }
    }
  }
  &.right {
    .tiptext {
      top: -100%;
      &::after {
        top: 50%;
        left: -9px;
        margin-top: -5px;
        border-color: transparent #202046 transparent transparent;
      }
    }
  }
}

.filter-input,
.hours-input {
  &::-webkit-input-placeholder {
    /* Edge */
    color: #97a3c5;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #97a3c5;
  }

  &::placeholder {
    color: #97a3c5;
  }
}

button {
  &.taclia-status-btn {
    border: solid 1px #3c48ec;
    color: #3c48ec;
    background-color: white;
    min-width: 173px;
    border-radius: 54px;
    display: inline-flex;
    letter-spacing: 0.1px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    padding: 10px 24px;
    margin-bottom: 24px;
    img {
      width: 20px;
      margin-left: 8px;
      margin-top: 1px;
    }
    &:disabled {
      background-color: #e3ebfa;
      color: #97a3c5;
    }
  }
}

//modals
.custom-content--btns:not(.custom-modal__footer .custom-content--btns) {
  padding: 20px 40px;
  text-align: right;
}

.empty-message {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #6a79a0;
}

// THIS IS IMPORTANT TO REMOVE STAR ICON IN PRO SUBSCRIPTION
.pro-feature {
  &.is-checkable {
    &.is-available {
      .icon-star-pro-feature,
      .pro-feature-upload {
        display: none;
      }
    }
  }
  &:not(.is-checkable) {
    .icon-star-pro-feature,
    .pro-feature-upload {
      display: none;
    }
  }
}

.tooltip-directive {
  text-align: center;
  z-index: 15000;
  position: fixed;
  padding: 6px 12px;
  font-size: 0.66rem;
  font-weight: 600;
  line-height: initial;
  color: white;
  width: auto;
  box-sizing: border-box;
  opacity: 0;
  transform: translate(-50%, -30%);
  animation: tooltip-slide 0.18s ease-out 0.5s;
  animation-fill-mode: forwards;
  pointer-events: none;
}

@keyframes tooltip-slide {
  0% {
    opacity: 0;
    transform: translate(-50%, -30%);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

.user-feature {
  &.is-checkable {
    &:not(.is-user-available) {
      //pointer-events: none;
    }
  }
}

.user-feature-disable {
  &.is-checkable {
    &:not(.is-user-available) {
      pointer-events: none;
    }
  }
}

.user-feature-hide {
  &.is-checkable {
    &:not(.is-user-available) {
      pointer-events: none;
      height: 0px;
      width: 0px;
      padding: 0px;
      display: none !important;
    }
  }
}

.wrapper-pro-feature-user {
  &:not(.is-user-available) {
    roma-ng-select,
    roma-select-search,
    roma-add-time,
    roma-rates-select-table,
    roma-mobile-modal-list,
    roma-rm-select-v2,
    rm-select-register,
    textarea,
    .custom-btn-save,
    .list-item,
    mat-checkbox,
    rm-select,
    .clean-input,
    .file-btn,
    label,
    .main-container,
    select,
    ng-select,
    mat-form-field,
    span,
    button,
    rm-status-select,
    .fake-select-overlay,
    .__hour_clear,
    roma-editable-field,
    mat-card,
    roma-rm-phone-select-input,
    input {
      pointer-events: none;
      opacity: 0.7;
    }
  }
}

// THIS IS IMPORT TO PRO FEATURE
.wrapper-pro-feature {
  position: relative;
  display: flex;
  width: 100%;
  &:not(.is-available) &:not(.is-user-available) {
    roma-ng-select,
    mat-checkbox,
    select,
    ng-select,
    rm-select,
    mat-form-field,
    span,
    button,
    input {
      pointer-events: none;
    }
    .pro-feature-element,
    .link__list__item__arrow {
      display: none;
    }
  }
}

.wrapper-pro-feature-none {
  position: relative;
  width: 100%;
  float: left;
  &:not(.is-available) &:not(.is-user-available) {
    roma-ng-select,
    mat-checkbox,
    select,
    ng-select,
    mat-form-field,
    span,
    button,
    input {
      pointer-events: none;
    }
  }
}

.wrapper-pro-feature-hideButton {
  position: relative;
  width: 100%;
  &:not(.is-available) &:not(.is-user-available) {
    roma-ng-select,
    mat-checkbox,
    select,
    ng-select,
    mat-form-field,
    span,
    input {
      pointer-events: none;
    }
    button {
      display: none;
    }
  }
}

.wrapper-pro-feature-element {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .description {
    flex: 1;
    margin: 0;
    max-width: 80%;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  &:not(.is-available) {
    roma-ng-select,
    mat-checkbox,
    select,
    ng-select,
    mat-form-field,
    span,
    input {
      pointer-events: none;
    }
  }
}

.field-label {
  font-weight: 600;
  font-size: 11px;
  line-height: 36px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  color: #6a79a0;
  img:not(.ignore-field-label) {
    filter: invert(73%) sepia(11%) saturate(756%) hue-rotate(187deg)
      brightness(88%) contrast(89%);
    margin-right: 6px;
    width: 20px;
  }
}

.add-field-btn {
  font-weight: 600;
  font-size: 11px;
  line-height: 36px;
  height: 36px;
  border-radius: 6px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  color: #3c48ec;
  background: #fff;
  border: none;
  &.btn-hidden {
    position: absolute;
    visibility: hidden;
    top: 0;
    z-index: 0;
  }
}

.active-items-list {
  .active-item {
    padding: 4px 8px;
    padding-right: 30px;
    width: fit-content;
    max-width: 200px;
    height: 36px;
    line-height: 30px;
    border-radius: 6px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    background: #f2f9ff;
    &:last-child {
      margin-bottom: 16px;
    }
    .clean-input {
      position: absolute;
      top: 10px;
      right: 8px;
      cursor: pointer;
    }
  }
}

// mobile list select

.list-dropdown-select {
  .search-input {
    position: relative;
    padding: 12px 16px;
    &.secondary {
      padding: 0;
      .filter-input {
        padding-left: 12px;
      }
    }
    img {
      position: absolute;
      left: 22px;
      top: 22px;
    }

    .filter-input {
      border: 1px solid #d3dbf2;
      border-radius: 8px;
      padding: 8px 12px;
      margin-top: 0;
      margin-bottom: 0;
      height: 36px;
      width: 100%;
      padding-left: 28px;
      cursor: pointer;
      outline: none;
      &:focus {
        border: 1px solid #15152e;
      }
      img {
        float: right;
      }
    }
  }
  .mobile-display {
    display: none;
    @media screen and (max-width: 768px) {
      display: block;
    }
  }
  .desktop-display {
    display: none;
    @media screen and (min-width: 768px) {
      display: block;
    }
  }
  .mobile-list-wrapper {
    background: rgba(15, 15, 22, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
  }
  .list-box {
    background: #fff;
    box-shadow: 0px -3px 5px rgba(177, 177, 177, 0.25);
    border-radius: 12px 12px 0px 0px;
    width: 100vw;
    min-height: 344px;
    max-height: 90vh;
    border-radius: 12px 12px 0px 0px;
    padding: 20px 24px 0 24px;
    position: fixed;
    bottom: env(safe-area-inset-bottom);
    left: 0;
    z-index: 101;
    h4 {
      color: #15152e;
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 15px;
    }
    .filter-input {
      margin: 8px 0 16px;
    }
    .list-wrapper {
      max-height: calc(90vh - 200px);
      overflow-y: auto;
      margin-bottom: calc(84px + env(safe-area-inset-bottom));
      &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #d3dbf2;
        border-radius: 12px;
      }
      .list-item {
        border-bottom: 1px solid #e3ebfa;
        height: 50px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        letter-spacing: 0.1px;
        color: #414b66;
        cursor: pointer;
        position: relative;
        @media screen and (max-width: 768px) {
          height: auto;
          padding-top: 8px;
          padding-bottom: 8px;
        }
        .active-check {
          width: 20px;
          position: absolute;
          left: 0;
          visibility: hidden;
        }
      }
    }
    .close-btn-wrapper {
      width: 100%;
      margin-top: 20px;
      margin-bottom: 24px;
      position: absolute;
      bottom: env(safe-area-inset-bottom);
      left: 0;
      .close-btn {
        margin: auto;
        cursor: pointer;
      }
    }
  }
}

button {
  &.list-select {
    box-sizing: border-box;
    color: #15152e;
    padding: 0;
    box-shadow: none !important;
    max-width: 110px;
    margin: -37px 5px 0;
    text-align: left;
    display: none;
    @media screen and (max-width: 375px) {
      max-width: 75px;
      margin-left: 0;
    }
    .mat-icon {
      height: 20px;
      width: 20px;
      margin-right: 10px;
      @media screen and (max-width: 375px) {
        margin-right: 5px;
      }
    }
    .arrow {
      margin-left: 5px;
      @media screen and (max-width: 340px) {
        margin-left: 0;
      }
    }
  }
}

.mobile-viewtype-wrapper {
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1200;
  // z-index: 3;
  background: rgba(15, 15, 22, 0.8);
  .viewtype-list {
    position: absolute;
    bottom: 0;
    background-color: #fff;
    width: 100%;
    border-radius: 12px 12px 0px 0px;
    padding: 20px 24px 0 24px;
    h4 {
      color: #15152e;
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 15px;
    }
    .close-btn {
      margin: auto;
      cursor: pointer;
    }
    .link-list {
      .list-item {
        border-bottom: 1px solid #e3ebfa;
        padding: 15px 20px 15px 45px;
        position: relative;
        width: 100%;
        cursor: pointer;
        &:hover {
          background-color: #f5f5f5;
        }
        .prev-icon {
          position: absolute;
          left: 0;
          top: 15px;
          margin: auto;
          svg {
            vertical-align: top;
          }
        }
        .icon-text {
          color: #15152e;
          font-weight: 400;
          font-size: 16px;
          letter-spacing: 0.5px;
          margin-bottom: 0;
        }
        p {
          font-size: 12px;
          color: #414b66;
          font-weight: 400;
          margin-bottom: 0;
          line-height: 16px;
          padding-right: 40px;
        }
        .arrow {
          width: 16px;
          float: right;
          position: absolute;
          right: 20px;
          top: 0;
          bottom: 0;
          margin: auto;
          cursor: pointer;
        }
      }
      .close-btn-wrapper {
        width: 100%;
        margin-top: 20px;
        margin-bottom: 24px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .mobile-hidden {
    display: none !important;
  }
  button.list-select {
    display: block !important;
  }
}

.content__title {
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #15152e;
  letter-spacing: 0.1px;
}

label {
  &.general-form-label {
    color: #15152e;
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 5px;
    letter-spacing: 0.1px;
  }
}

.general-form-control {
  font-family: Metropolis;
  margin: 0;
  background: #ffffff;
  border: 1px solid #c1cdf5 !important;
  box-sizing: border-box;
  border-radius: 8px !important;
  min-height: 52px;
  font-size: 14px !important;
  color: #15152e !important;
  padding: 0 16px;
  &::placeholder {
    font-size: 14px;
    color: #8c9dd2;
    text-transform: none !important;
  }
  &:disabled {
    background-color: #f7f9ff;
    opacity: 1;
    color: #5d6b98 !important;
  }
  &.md {
    min-height: 44px;
  }
}

.general-invalid-feedback {
  color: #ff707e !important;
  font-size: 12px;
  display: block;
}

.general-form-control:invalid,
.general-form-control.is-invalid {
  background-image: none !important;
  border: 1px solid #ff707e !important;
}

.form-group {
  &.mb {
    margin-bottom: 24px;
  }
}

.input-number-days {
  .input-wrapper {
    position: relative;
  }

  .input-wrapper input {
    padding-right: 50px;
  }

  .input-append {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }

  .input-days {
    padding: 10px 16px;
    border: 1px solid #c1cdf5;
    border-radius: 9px;
    width: 100%;
    &:focus {
      outline: none;
    }
  }

  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
}

.no-registers {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 30px;
  &__title {
    color: #343e5e;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    margin: 12px 0 8px 0;
  }
  &__text {
    font-size: 16px;
    font-weight: 400;
    color: #5d6b98;
    line-height: 24px;
    margin-bottom: 20px;
  }
  &__button {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    gap: 8px;
    border: 1px solid #3c48ec;
    background: #3c48ec;
    color: #ffffff;
    border-radius: 90px;
    letter-spacing: 0.1px;
  }
}

.bottom-sheet {
  border-radius: 12px 12px 0px 0px;
}

a.button-sidebar .mat-button-wrapper {
  width: 100%;
  text-align: start;
}

/**
 * Hides the Google reCAPTCHA badge.
 *
 * @class .grecaptcha-badge
 * @description This class is used to hide the Google reCAPTCHA badge.
 */
.grecaptcha-badge {
  display: none !important;
}

// Zendesk styles
#launcher {
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.pac-container {
  position: fixed !important;
  z-index: 100000 !important;
}

.full-screen-dialog > mat-dialog-container {
  border-radius: 0px !important;
}
